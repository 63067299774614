.notification-badge-container{
  width: 360px;
  max-height: 559px;
  overflow: auto;
  padding: 0;
}

.notification-badge-header-container{
  padding: 0.5rem 1rem;
  border-bottom: 1px solid #CCC;
}

.notification-badge-header{
  font-size: 20px;
  font-weight: bold;
}

.view-all-button{
  font-size: 16px !important;
  font-weight: bold!important;
  color:#6956BA!important;
}

#notification-menu{
  border-radius: 0;
}

.avatar{
  height: 32px !important;
  width: 32px !important;
  background-color: #DDE0FF!important;
  color: #666!important;
  text-align: center!important;
  font-family: Roboto!important;
  font-size: 20px!important;
  font-style: normal!important;
  font-weight: 300!important;
  line-height: normal!important;
}

.notification-details{
  cursor: pointer;
  display: flex;
  gap:10px;
  color: #333;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; 
  border-bottom: 1px solid #E4E4E4;
  padding: 0.5rem 1rem;
}

.notification-details:hover{
  background-color: #F2F3FF;
}

.greyed-out{
  opacity:0.5;
}
.greyed-out:hover{
  background-color: #CCC;
  opacity:0.5;
}

.notification-details-time{
  color:  #999;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  margin-top: 0.25rem;
}

.no-notification-container{
  color: #999999;
  height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
}